<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">学期:</div>
            <el-select v-model="semesterId" placeholder="请选择" class="select_container">
                <el-option
                        v-for="item in this.$store.state.chinese.semester.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="manage_container">
            <div class="manage">出版社名称:</div>
            <el-input class="manage_input" type="text" size="small" v-model="publishingName"></el-input>
        </div>
        <div class="manage_container">
            <div class="manage">教材封面:</div>
            <el-upload
                    v-loading="loading"
                    class="image-upload"
                    action=""
                    :show-file-list="false"
                    :auto-upload="true"
                    :http-request="imageUpload">
                <el-image v-if="localImageUrl" :src="localImageUrl" class="image" fit="fit"></el-image>
                <i v-else class="el-icon-plus image-upload-icon"></i>
            </el-upload>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="oncancel">取消</el-button>

    </div>

</template>

<script>
    import axios from '@/libs/axios'
    import {compressFile} from "@/utils/fileUtil";

    export default {
        name: "add",

        data() {
            return {
                semesterId: '',//学期id
                publishingName:'',//出版社名称
                name:'语文',//教材名称（语文）
                subjectId:'1',//学科Id（语文为1）
                imageUrl: '', // OSS 图片地址 https://www.aliyun.com/a.png
                localImageUrl: '', // 本地图片地址 blob:http://localhost:8081/771cddf2-6caf-461b-bd02-7c78057faed9
                loading: false,

            }
        },
        mounted() {
            this.$store.dispatch("chinese/semesterList");

        },
        methods: {
            //确认添加
            onConfirm() {
                if (!this.publishingName) {
                    this.$message.warning('请输入出版社名称');
                }else if(!this.localImageUrl){
                    this.$message.warning('请上传教材封面');
                }else {
                    this.$store.dispatch("chinese/addBook", {
                        'name': this.name,
                        'subjectId': this.subjectId,
                        'publishing':this.publishingName,
                        'semesterId':this.semesterId,
                        'coverUrl':this.imageUrl
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }

            },
            //取消添加
            oncancel() {
                this.$router.go(-1);
            },
            imageUpload(request) {
                this.loading = true;
                    axios.upload("/v1/web/file/book", request.file).then(response => {
                        this.localImageUrl = URL.createObjectURL(request.file);
                        this.imageUrl = response.data.data.url;
                        this.loading = false;
                        console.log(this.imageUrl);
                        console.log(this.localImageUrl);
                    }).catch((e) => {
                        console.log(e)
                    });
            },


        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }
        .image-upload, .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 12px;
            cursor: pointer;
            overflow: hidden;
            display: inline-block;
            width: 105px;
            height: 150px;

            .image {
                width: 105px;
                height: 150px;
                display: block;
            }

            .image-upload-icon {
                font-size: 28px;
                color: #8c939d;
                width: 105px;
                height: 150px;
                line-height: 150px;
                text-align: center;
            }
        }

    }

</style>
